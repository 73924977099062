<template>
  <v-sheet
    color="grey lighten-4"
    class="
      rounded-r-sm elevation-2
      d-flex flex-column justify-center align-center
      text-center
    "
    height="100%"
  >
    <h2>
      Olá {{ userName }},
      <br/>
      Bem-vindo ao Tesys, o Assistente Contábil.
    </h2>
    <p class="mt-4">
      Para iniciar seu Assistente utilize o menu de navegação
      <br/>
      ou clique em uma das opções abaixo:
    </p>
    <div class="d-flex">
      <v-card
        width="120px"
        color="primary darken-1"
        :elevation="3"
        class="
          ma-2 pt-4 px-1 rounded-sm
          d-flex flex-column justify-center align-center
        "
        v-for="(item, i) in menu" :key="i"
        :to="item.to"
      >
        <v-img
          v-if="item.img"
          max-width="35px"
          max-height="35px"
          :src="require(`@/assets/menu/${item.img}.png`)"
          contain
        />
        <v-icon v-else size="35" color="white">
          {{ item.icon }}
        </v-icon>
        <p class="m-0 white--text pt-1">
          {{ item.text }}
        </p>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import menu from "@/menu.js";

export default {
  name: "WelcomePage",
  computed: {
    userName(){
      if (!this.$store.state.logged)
        return "";
      return this.$store.state.user.name;
    },
    menu(){
      return menu.filteredMenu(this.$store.state.user)
    }
  },
};
</script>
